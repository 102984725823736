exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-alternativa-tsx": () => import("./../../../src/pages/alternativa.tsx" /* webpackChunkName: "component---src-pages-alternativa-tsx" */),
  "component---src-pages-apartamenty-stoit-li-pokupat-tsx": () => import("./../../../src/pages/apartamenty-stoit-li-pokupat.tsx" /* webpackChunkName: "component---src-pages-apartamenty-stoit-li-pokupat-tsx" */),
  "component---src-pages-chto-takoe-smezhnye-komnaty-tsx": () => import("./../../../src/pages/chto-takoe-smezhnye-komnaty.tsx" /* webpackChunkName: "component---src-pages-chto-takoe-smezhnye-komnaty-tsx" */),
  "component---src-pages-chto-takoe-sobstvennost-menee-3-let-tsx": () => import("./../../../src/pages/chto-takoe-sobstvennost-menee-3-let.tsx" /* webpackChunkName: "component---src-pages-chto-takoe-sobstvennost-menee-3-let-tsx" */),
  "component---src-pages-cian-tsx": () => import("./../../../src/pages/cian.tsx" /* webpackChunkName: "component---src-pages-cian-tsx" */),
  "component---src-pages-contacts-victor-kupriyanov-aboutme-tsx": () => import("./../../../src/pages/contacts_victor_kupriyanov_aboutme.tsx" /* webpackChunkName: "component---src-pages-contacts-victor-kupriyanov-aboutme-tsx" */),
  "component---src-pages-contacts-victor-kupriyanov-reviews-tsx": () => import("./../../../src/pages/contacts_victor_kupriyanov_reviews.tsx" /* webpackChunkName: "component---src-pages-contacts-victor-kupriyanov-reviews-tsx" */),
  "component---src-pages-contacts-victor-kupriyanov-tsx": () => import("./../../../src/pages/contacts_victor_kupriyanov.tsx" /* webpackChunkName: "component---src-pages-contacts-victor-kupriyanov-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-faq-yandex-arenda-tsx": () => import("./../../../src/pages/faq_yandex_arenda.tsx" /* webpackChunkName: "component---src-pages-faq-yandex-arenda-tsx" */),
  "component---src-pages-formy-raschetov-v-sdelkakh-nedvizhimosti-tsx": () => import("./../../../src/pages/formy-raschetov-v-sdelkakh-nedvizhimosti.tsx" /* webpackChunkName: "component---src-pages-formy-raschetov-v-sdelkakh-nedvizhimosti-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kak-prodat-kvartiru-sdelay-horoshie-fotografii-tsx": () => import("./../../../src/pages/kak-prodat-kvartiru-sdelay-horoshie-fotografii.tsx" /* webpackChunkName: "component---src-pages-kak-prodat-kvartiru-sdelay-horoshie-fotografii-tsx" */),
  "component---src-pages-osnovnye-dokumenty-k-sdelke-tsx": () => import("./../../../src/pages/osnovnye-dokumenty-k-sdelke.tsx" /* webpackChunkName: "component---src-pages-osnovnye-dokumenty-k-sdelke-tsx" */),
  "component---src-pages-osnovnye-dokumenty-po-kvartire-tsx": () => import("./../../../src/pages/osnovnye-dokumenty-po-kvartire.tsx" /* webpackChunkName: "component---src-pages-osnovnye-dokumenty-po-kvartire-tsx" */),
  "component---src-pages-purchase-real-estate-tsx": () => import("./../../../src/pages/purchase-real-estate.tsx" /* webpackChunkName: "component---src-pages-purchase-real-estate-tsx" */),
  "component---src-pages-purchase-riski-pri-pokupke-kvartiry-i-ih-minimizaciya-tsx": () => import("./../../../src/pages/purchase-riski-pri-pokupke-kvartiry-i-ih-minimizaciya.tsx" /* webpackChunkName: "component---src-pages-purchase-riski-pri-pokupke-kvartiry-i-ih-minimizaciya-tsx" */),
  "component---src-pages-purchase-uslugi-rieltora-pri-pokupke-kvartiry-tsx": () => import("./../../../src/pages/purchase-uslugi-rieltora-pri-pokupke-kvartiry.tsx" /* webpackChunkName: "component---src-pages-purchase-uslugi-rieltora-pri-pokupke-kvartiry-tsx" */),
  "component---src-pages-sale-real-estate-tsx": () => import("./../../../src/pages/sale-real-estate.tsx" /* webpackChunkName: "component---src-pages-sale-real-estate-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-soprovozhdenie-sdelki-kupli-prodazhi-tsx": () => import("./../../../src/pages/soprovozhdenie-sdelki-kupli-prodazhi.tsx" /* webpackChunkName: "component---src-pages-soprovozhdenie-sdelki-kupli-prodazhi-tsx" */),
  "component---src-pages-stoimost-kvadratnogo-metra-zhilya-v-moskve-po-rayonam-tsx": () => import("./../../../src/pages/stoimost-kvadratnogo-metra-zhilya-v-moskve-po-rayonam.tsx" /* webpackChunkName: "component---src-pages-stoimost-kvadratnogo-metra-zhilya-v-moskve-po-rayonam-tsx" */),
  "component---src-pages-vykup-iz-pod-zaloga-sberbanka-s-oslozhneniyami-tsx": () => import("./../../../src/pages/vykup-iz-pod-zaloga-sberbanka-s-oslozhneniyami.tsx" /* webpackChunkName: "component---src-pages-vykup-iz-pod-zaloga-sberbanka-s-oslozhneniyami-tsx" */)
}

